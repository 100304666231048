import { AnyObject, Maybe, ObjectSchema } from 'yup';

export type PartialNullableDeep<T> = T extends
  | string
  | number
  | bigint
  | boolean
  | null
  | undefined
  | symbol
  | Date
  ? T | null | undefined
  : T extends Array<infer ArrayType>
    ? Array<PartialNullableDeep<ArrayType>>
    : T extends ReadonlyArray<infer ArrayType>
      ? ReadonlyArray<ArrayType>
      : {
          [K in keyof T]?: PartialNullableDeep<T[K]>;
        };

// Makes schema to have all types as T | null | undefined
export const deepPartialOrNullable = <TIn extends Maybe<AnyObject>>(
  schema: ObjectSchema<TIn>,
): ObjectSchema<PartialNullableDeep<TIn>> => {
  if ('fields' in schema) {
    const partial: any = {};
    for (const [key, fieldSchema] of Object.entries(schema.fields)) {
      partial[key] = deepPartialOrNullable(fieldSchema);
    }
    return (schema as any).setFields(partial);
  }
  if ((schema as any).type === 'array') {
    const nextArray = (schema as any).optional().nullable();
    if (nextArray.innerType)
      nextArray.innerType = deepPartialOrNullable(nextArray.innerType);
    return nextArray;
  }
  if ((schema as any).type === 'tuple') {
    return (schema as any)
      .nullable()
      .optional()
      .clone({ types: (schema as any).spec.types.map(deepPartialOrNullable) });
  }
  if ('optional' in schema || 'nullable' in schema) {
    return (
      (schema as any)
        .nullable()
        .optional()
        // AI can return 'null' as string instead of null
        .transform((value: unknown, originalValue: unknown) =>
          originalValue === 'null' ? null : value,
        )
    );
  }
  return (schema as any).transform((value: unknown, originalValue: unknown) =>
    originalValue === 'null' ? null : value,
  );
};
